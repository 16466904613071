import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as urls from '../../common/end-points/urls';
import { RecruiterCommonService } from '../recruiter-common/recruiter-common.service';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  dataToEdit: any;
  closeEvent: Subject<any> = new Subject();
  offerStepper = 1;
  selectedOffer: any;

  constructor(
    private http: HttpClient,
    public dashboard: DashboardService,
    public commonRecData: RecruiterCommonService
  ) { }

  getData() {
    // return this.http.get<any>(
    //   `${urls.profile.getData}/${localStorage.getItem('vendor_id')}`
    // );
    return this.http.get<any>(`${urls.profile.getData}`);

  }

  getWalletBal() {
    return this.http.get<any>(`${urls.profile.walletBalance}`);
  }

  updateActiveStatus() {
    if (!this.commonRecData.recruiterProfileData) {
      this.getData().subscribe(
        (res) => {
          this.commonRecData.recruiterProfileData = res[0];
          localStorage.setItem('is_verified', res[0].is_verified)
        }
      );
    } else {
      localStorage.setItem('is_verified', this.commonRecData.recruiterProfileData.is_verified)
    }
  }


  updateProfile(body: any, id?: any) {
    return this.http.patch<any>(`${urls.profile.updateData}/${id}`, body);
  }

  updateProfile2(body: any) {
    return this.http.patch<any>(`${urls.profile.updateData}`, body);
  }

  closeRecruiterSection(val: any) {
    this.closeEvent.next(val);
  }

  ledgerBalance() {
    return this.http.get<any>(urls.auth.ledgerBalance);
  }

}
