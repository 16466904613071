import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HomeService } from 'src/app/services/home/home.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as urls from '../../common/end-points/urls';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toaster: ToastrService,
    private router: Router,
    public auth: AuthService,
    public dashboard: DashboardService,
    public homeService: HomeService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error) {
          let errorPayload = {
            "statusCode": error.status,
            "error": error?.error?.msg,
            "device": 2,
            "url": error?.url,
            "vendor_user_id": localStorage.getItem('vendor_user_id'),
            "candidate_id": localStorage.getItem('_id')
          }

          switch (error.status) {
            case 400:
              if (error.error.msg == "Insufficient coin balance") {
                this.dashboard.openInsufficientBox();
              }
              if (error.error.errors) {
                const modalStateErrors = [];
                for (const key in error.error.errors) {
                  if (error.error.errors[key]) {
                    modalStateErrors.push(error.error.errors[key]);
                  }
                }
                throw modalStateErrors;
              } else {

                // this.toaster.error(error.statusText, error.msg);
              }

              break;

            case 401:
              // this.toaster.error(error.statusText, error.error.message);
              //  this.auth.logOut();
              //  this.homeService.userTypecheck();

              break;
            case 404:
              this.toaster.error('Page Not Found');
              break;
            case 403:
              if (error?.url != 'https://msdev.hirewalks.com/subscription/v1/vendor/ledger-balance') {
                this.toaster.error(
                  'Access denied',
                  'Access Issue'
                );
              }
              break;
            case 500:
              // this.toaster.error(error.statusText, error.error.msg);
              break;

            case 422:
              this.toaster.error(error.error.error.code);
              break;
            case 410:
              //this.toaster.error(error.error.msg);
              break;
            default:
              // this.toaster.error('Something unexpected went wrong');
              // this.router.navigate(['/'])
              break;
          }
          if ((error.status !== 401 && errorPayload?.url != urls.auth.errorAPI) && (error.status !== 410 || (errorPayload?.vendor_user_id || errorPayload?.candidate_id))) {
            // Call the API if the status code is not 401 and not the error update api
            // If the status code is 410, ensure vendor_user_id or candidate_id is present.
            // If the status code is not 410, no need to check for vendor_user_id.
            
            this.auth.errorApi(errorPayload).subscribe(res => {
              console.log('error API called');
            })
          }
        }
        return throwError(error);
      })
    );
  }
}
