const baseUrl = 'https://uat-ms.hirewalks.com';
/* https://uat-ms.hirewalks.com/ */

export const environment = {
    environment: "uat",
    refreshApiCallTime: (24 * 60 * 60 * 1000),
    firebaseAuth: false,
    production: true,
    baseUrl: 'https://uat-ms.hirewalks.com',
    server: 'https://uat-ms.hirewalks.com/',
    ulrToDisplayImage: 'https://uat-ms.hirewalks.com/api/v1/details/imagedisplay/',
    socketURL: 'https://dev.hirewalks.com/api/v1', //for socket connection
    candidateURL: 'https://landingpage.hirewalks.com',
    recruiterURL: 'https://landingpage.hirewalks.com/recruiters',
    feedBackUrl: 'https://uat-ms.hirewalks.com',
    careerPageLink: 'https://career-uat.hirewalks.com/',
    encryptSecretKey: 'vuobnvlkdjvndvjkfnkjdlvndskvlnhuisdvbdslvbdslvbdsvdbuvdvhfvjjvbfjvbf',

    //base URls
    hrBasePath: baseUrl + '/hr',
    jobsBasePath: baseUrl + '/jobs-api',
    jobsAtsBasePath: baseUrl + '/jobATS',
    masterBasePath: baseUrl + '/master',
    chatBasePath: baseUrl + '/chat',
    offersBasePath: baseUrl + '/offers',
    subsBasePath: baseUrl + '/subscription',
    vendorNotifyBasePath: baseUrl + '/vendorNotifications',
    candBasePath: baseUrl + '/candidate-api',
    profileBasePath: baseUrl + '/profileSearch',
    candAuthBasePath: baseUrl + '/candidateAuth',
    vendorAuthBasePath: baseUrl + '/vendorAuth',
    devAtsBasePath: baseUrl + '/jobATS',

    firebaseConfig: {
        apiKey: 'AIzaSyC_kSRScso7HKIAgQOlxwf8i2WMjeHuIvo',
        authDomain: 'hirewalks-16c25.firebaseapp.com',
        projectId: 'hirewalks-16c25',
        storageBucket: 'hirewalks-16c25.appspot.com',
        messagingSenderId: '14576892119',
        appId: '1:14576892119:web:71a6065c5a8055faf21892',
        measurementId: 'G-QWC5TBVDBY',
    },
};
