import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as urls from '../../common/end-points/urls';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { LocationModel } from '../../common/models/location-model';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { PanelService } from '../pannel/panel.service';
import { NotificationService } from '../notification/notification.service';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile/profile.service';
import { RecruiterCommonService } from '../recruiter-common/recruiter-common.service';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  selectedProfileNav = 0;
  encPassword =
    'eyJhZG1pbl9pZCI6IjYxNTMzMzllOGVmOWM4NzQ2YzBlMjY0YyIsImlhdCI6MTYzNDAxNTQzNywiZXhwIjoxNjM0MDE5MDM3fQ';
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  userName: any =
    localStorage.getItem('user_name') ||
    localStorage.getItem('vendor_user_name') ||
    localStorage.getItem('contact_no') ||
    localStorage.getItem('user_email') ||
    '';


  userLogo: any = localStorage.getItem('logo');
  userType: any = parseInt(localStorage.getItem('vendor_type') || '');

  candidateId: any =
    localStorage.getItem('candidate_id') || localStorage.getItem('_id') || '';
  postJobToggle: boolean = false;
  postJobShowToggle: boolean = true;
  ip: any;
  mobileData: any = {};
  allLocationsByKeyword: any;
  otpResponse: any;
  headers = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  forgotToggle: boolean = false;



  constructor(
    private router: Router,
    private http: HttpClient,
    private firebase: AngularFireAuth,
    public sidenavService: PanelService,
    private notification: NotificationService,
    public profile: ProfileService,
    public commonRecData: RecruiterCommonService,
    public dashboard: DashboardService,
  ) { }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  // api call to signup
  signUp(data: any) {
    return this.http.post<any>(urls.auth.signUp, data).pipe(retry(0));
  }

  signUpAnotherUser(data: any) {
    return this.http.post<any>(urls.auth.signUpAnotherUser, data);
  }

  // api call to login
  login(data: any) {
    return this.http.post<any>(urls.auth.login, data);
  }

  // api call to login
  candidatelogin(data: any) {
    return this.http.post<any>(urls.auth.candidatelogin, data);
  }

  // api call to mobile login
  mobilelogin() {
    // return this.http.post<any>(urls.auth.mobilelogin, data);
    return this.http.post<any>(`${urls.auth.mobilelogin}`, this.mobileData);
  }

  v1Mobilelogin() {
    // return this.http.post<any>(urls.auth.mobilelogin, data);
    return this.http.post<any>(`${urls.auth.v1Mobilelogin}`, this.mobileData);
  }

  mobilesignup() {
    // return this.http.post<any>(urls.auth.mobilelogin, data);
    return this.http.post<any>(`${urls.auth.mobilesignup}`, this.mobileData);
  }

  chatCount(data: any) {
    return this.http.get<any>(`${urls.chat.chatCount}?user_id=${data}`);
  }
  //api call to get locations
  locations(): Observable<any> {
    let qb = '';

    if (this.allLocationsByKeyword) {
      qb = qb + 'keyword=' + this.allLocationsByKeyword + '&';
    }

    return this.http.get<LocationModel[]>(`${urls.auth.locations}?${qb}`);

    // return this.http.get<LocationModel[]>(urls.auth.locations);
  }

  //api call to get organization details
  OrganizationSearch(params?: any) {
    return this.http.get<any>(`${urls.auth.organizationSearch}`, {
      params: params,
    });
  }

  //function to update refresh token
  updateRefreshToken(token: any): Observable<any> {
    return this.http.post(`${urls.auth.refreshTokens}`, token);
  }

  //function to update refresh token
  deleteVendor(): Observable<any> {
    return this.http.delete(`${urls.auth.deleteVendor}`);
  }
  //function to update CANDIDATE refresh token
  updateCandidateRefreshToken(token: any): Observable<any> {
    return this.http.post(`${urls.auth.refreshTokens2}`, token);
  }

  // google sign in

  public signInWithGooglePopup() {
    var config = environment.firebaseConfig

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    } else {
      firebase.app();
    }

    return this.firebase
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((res: any) => {
        return res;
      });
  }

  public getAuthCredForGoogleSignIn(token: any): Observable<any> {
    return this.http.post(urls.auth.googleSignIn, token).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  //funtion to set authtoken
  setAuthToken(token: string): any {
    localStorage.setItem('token', token);
  }
  //function to get authToken
  getAuthToken(): string {
    // return localStorage.getItem('authToken') || '';
    return localStorage.getItem('token') || '';
  }

  logOut(): any {
    this.profile.closeRecruiterSection('recSidebar');
    let token = localStorage.getItem('token');
    let admin = localStorage.getItem('vendor_type');
    this.dashboard.scopes = [];

    setTimeout(() => {
      this.loggedIn.next(false);
      localStorage.clear();
      if (token && admin) { this.router.navigate(['/recruiter-login']); }
      if (token && !admin) {//non admin and token exists
        this.router.navigate(['/candidate-login']);
      }
      this.userName = null;
      this.userType = null;
      this.candidateId = null;
      localStorage.removeItem('logo');
      // sessionStorage.removeItem('recruiter_vid');
      localStorage.removeItem('recruiter_vid');
      this.firebase.signOut()
      // this.router.navigate(['/']); 
      // window.location.assign(environment.recruiterURL);
      this.profile.closeRecruiterSection('rec-close');
      this.commonRecData.recruiterProfileData = '';
    }, 200);

  }

  forgotMailCheck(req: any) {
    return this.http.post<any>(`${urls.auth.forgotPassword}`, req);
  }

  candidateforgotMailCheck(req: any) {
    return this.http.post<any>(`${urls.auth.candidateforgotPassword}`, req);
  }

  otpVerify(req: any) {
    return this.http.post<any>(`${urls.auth.passToken}`, req);
  }
  candidateOtpVerify(req: any) {
    return this.http.post<any>(`${urls.auth.candidatePasstoken}`, req);
  }

  save(password: any, token: any) {
    return this.http.post<any>(`${urls.auth.resetWithOtp}`, password, {
      headers: new HttpHeaders({
        Authorization: token.passtoken,
      }),
    });
  }
  candidateSave(password: any, token: any) {
    return this.http.post<any>(`${urls.auth.candidateResetWithOtp}`, password, {
      headers: new HttpHeaders({
        passtoken: token.passtoken,
      }),
    });
  }

  errorApi(payload: any) {
    return this.http.post<any>(`${urls.auth.errorAPI}`, payload);
  }
}
