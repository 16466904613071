import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import * as urls from 'src/app/common/end-points/urls'
import { MatDialog } from '@angular/material/dialog';
import { InsufficientPopupComponent } from 'src/app/common/dialog-popup/insufficient-popup/insufficient-popup.component';
import { HttpService } from '../http/http.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { RecruiterCommonService } from '../recruiter-common/recruiter-common.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  pageEvent: Subject<any> = new Subject();
  dashboardContent: boolean = false;
  jobpagecontent: boolean = false;
  findcandidateContent: boolean = false;
  candidateProfile: boolean = false;
  postJobPage: boolean = false;
  pricingPage: boolean = false;
  transactionPage: boolean = false;
  jobdetailpage: boolean = false;
  campaignpage: boolean = false;
  offerCheckPage: boolean = false;
  isTopBarHided = false;
  scopes: any;
  encryptSecretKey = environment.encryptSecretKey;

  /* Sidebar */
  isBuyCoinEnabled = true;
  isDbSearchEnabled = true;
  isUserManagementEnabled = true;
  isCareerPgEnabled = true;
  isOfferEnabled = true;
  isPostJobEnabled = true;

  /* Topbar */
  isNotifyEnabled = true;
  isChatEnabled = true;
  isTransactionEnabled = true;
  isChangePasswordEnabled = true;

  constructor(
    private http: HttpClient,
    public method: HttpService,
    public dialog: MatDialog,
    public commonRecData: RecruiterCommonService,
    public auth: AuthService,) { }


  //api call to get jobpostcount
  jobpostcount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.jobpostcount + "/" + type)
  }

  //api call to get interviewcount
  interviewcount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.interviewcount + "/" + type)
  }

  //api call to get promocount
  promocount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.promocount + "/" + type)
  }

  //api call to get hiredcount
  hiredcount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.hiredcount + "/" + type)
  }


  recPages(val: any) {
    // this.pageEvent.next(val); 

    if (val == "dashboardpage") {
      this.dashboardContent = true;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false;
      this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "joblistpage") {
      this.dashboardContent = false;
      this.jobpagecontent = true;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false;
      this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "campaignpage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false;
      this.campaignpage = true;
      this.offerCheckPage = false;
    }
    else if (val == "candidateSearch") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = true;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "candidateProfile") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = true;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;

    }
    else if (val == "postJobPage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = true;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "pricingPage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = true;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "transactionPage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = true;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "jobdetailpage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = true; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "offerChecks") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = true;
    }
    else if (val == "withoutTitle") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }

  }

  openInsufficientBox() {
    const dialogRef = this.dialog.open(InsufficientPopupComponent, {
      // panelClass: 'medium-dialog',
      // maxWidth: '400px',
      panelClass: 'reason-dialog',
      width: '58rem'
    });
  }


  /* ########## SCOPE Functions Start ##########  */
  getScopes(role: any, clearScope?: any) {
    if (clearScope) {
      if (!role) {
        this.scopes = [];
        this.getScopeObjFromLocal();
        return;
      }
    }

    if (role) {
      const payload = {
        roleId: role
      }

      this.method.getRequest(urls.auth.userMangementScopes, payload).subscribe((res) => {
        this.scopes = res;
        const scopeObjEncrypt = CryptoJS.AES.encrypt(JSON.stringify(res), this.encryptSecretKey).toString();
        localStorage.setItem('scopes', scopeObjEncrypt);
        this.getScopeObjFromLocal();
      })
    }
  }

  getScopeObjFromLocal() {
    if (localStorage.getItem('scopes')) {
      const encryptedScopes = localStorage.getItem('scopes');

      if (encryptedScopes) {
        const decryptedScopes = CryptoJS.AES.decrypt(encryptedScopes, this.encryptSecretKey).toString(CryptoJS.enc.Utf8);
        this.scopes = JSON.parse(decryptedScopes);
      }

      this.setDefaultScopeValues(); // set default values if no scopes
    }

    this.setScopePermissions(); // set the permissions based on scopes
    return true;
  }

  setDefaultScopeValues() {
    /* Sidebar */
    this.isBuyCoinEnabled = true;
    this.isDbSearchEnabled = true;
    this.isUserManagementEnabled = true;
    this.isCareerPgEnabled = true;
    this.isOfferEnabled = true;
    this.isPostJobEnabled = true;

    /* Topbar */
    this.isNotifyEnabled = true;
    this.isChatEnabled = true;
    this.isTransactionEnabled = true;
    this.isChangePasswordEnabled = true;
  }

  setScopePermissions() {
    if (this.scopes) {
      /* Sidebar */
      this.isBuyCoinEnabled = this.isOptionEnabled('Account', 'Buy Coins');
      this.isDbSearchEnabled = this.isOptionEnabled('Master', 'DB Search');
      this.isUserManagementEnabled = this.isOptionEnabled('Master', 'User management');
      this.isCareerPgEnabled = this.isOptionEnabled('Master', 'Career Page Setup');
      this.isOfferEnabled = this.isOptionEnabled('Master', 'Offer Check');
      this.isPostJobEnabled = this.isOptionEnabled('Jobs', 'Post Job');

      /* Topbar */
      this.isNotifyEnabled = this.isOptionEnabled('Master', 'Notification');
      this.isChatEnabled = this.isOptionEnabled('Master', 'Chat');
      this.isTransactionEnabled = this.isOptionEnabled('Account', 'Transaction');
      this.isChangePasswordEnabled = this.isOptionEnabled('Account', 'Change Password');
    }
  }

  //PARAMS --> 1. Group name 2. Category
  isOptionEnabled(groupName: any, subCategoryItem: any) {
    if (this.scopes) {
      // Find the group by name
      const group = this.scopes.find((g: any) => g.group === groupName);

      // If group is found, find the subcategory item
      if (group) {
        const subCategory = group.subCategory.find((sc: any) => sc.item === subCategoryItem);

        // If subcategory item is found, return its isEnabled status
        if (subCategory) {
          return subCategory.isEnabled;
        } else {
          console.warn(`Subcategory item '${subCategoryItem}' not found`)
          return true;
        }
      } else {
        console.warn(`Group '${groupName}' not found`)
        return true;
      }
    } else if (localStorage.getItem('tenant')) {
      return true;
    }
  }

  // Function to update all `isEnabled` values to false
  /* disableAllItems = (dataArray:any) => {
    return dataArray.map((group:any) => {
      return {
        ...group,
        subCategory: group.subCategory.map((sub:any) => {
          return { ...sub, isEnabled: false };
        })
      };
    });
  }; */

  /* ########## SCOPE Functions End ##########  */

}
